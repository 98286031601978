#site-header{
    $mail: 100px;
    width: 100%;
    height: $pc-head;
    position: fixed;
    background: #FFF;
    @include flex();
    align-items: center;
    padding: 0 $mail 0 0;
    z-index: 1000;
    @media screen and (max-width:$width - 1px){
        height: $sp-head;
        padding: 0;
        position: absolute;
    }
    &.active{
        box-shadow: 0 0 32px rgba($normal,.12);
    }
    .logo{
        position: relative;
        margin: 0 0 0 32px;
        @media screen and (max-width:$width - 1px){
            margin: 0 0 0 15px;   
        }
        img{
            width: 240px;
            @media screen and (max-width:$width - 1px){
                width: 200px;   
            }
        }
        h1{
            position: absolute;
            top: 1px;
            left: 72px;
            white-space: nowrap;
            font-size: 11px;
            font-weight: 700;
            color: $normal;
            @media screen and (max-width:$width - 1px){
                font-size: 10px;
                top: -3px;
                left: 60px;
            }
        }
    }
    .gnavi{
        @media screen and (max-width:1200px){
            margin: 0 40px 0 0;
        }
        @media screen and (min-width:$width){
            i.trigger,
            i.close{
                display: none;
            }   
        }
        @media screen and (max-width:$width - 1px){
            margin: 0;
            position: fixed;
            top: 0;
            right: 0;
            i.trigger{
                width: 40px;
                height: 40px;
                background: $main;
                border-radius: 50%;
                margin: 0 15px 0 0;
                top: 11px;
                right: 0;
                display: block;
                position: relative;
                cursor: pointer;
                &:before,
                &:after{
                    content: '';
                    position: absolute;
                    top: 41%;
                    left: 50%;
                    background: #FFF;
                    transform: translateX(-50%);
                    width: 16px;
                    height: 1px;
                }
                &:after{
                    top: auto;
                    bottom: 41%;
                }
            }
            i.close{
                width: 40px;
                height: 40px;
                background: $main;
                border-radius: 50%;
                margin: 0 15px 0 0;
                display: block;
                position: fixed;
                cursor: pointer;
                z-index: 6000;
                top: 11px;
                right: 0;
                &:before,
                &:after{
                    content: '';
                    position: absolute;
                    top: 50%;
                    left: 12px;
                    background: #FFF;
                    transform: rotate(45deg);
                    width: 16px;
                    height: 1px;
                }
                &:after{
                    transform: rotate(-45deg);
                }
            }
        }
        ul{
            @media screen and (min-width:$width){
                display: flex !important;
                align-items: center;
                justify-content: center;
                li{
                    margin: 0 0 0 48px;
                    @media screen and (max-width:1300px){
                        margin: 0 0 0 40px;
                    }
                    &:first-child{
                        margin: 0;
                    }
                    &.sp{
                        display: none;
                    }
                    a{
                        color: $normal;
                        display: block;
                        text-align: center;
                        font-size: 13.5px;
                        font-weight: 700;
                        line-height: 1;
                        position: relative;
                        &:after{
                            content: attr(data-eng);
                            display: block;
                            font-weight: 600;
                            font-size: 10px;
                            color: $main;
                            margin: 6px 0 0;
                        }
                        &:before{
                            content: '';
                            width: 9px;
                            height: 9px;
                            border-radius: 50%;
                            background: $main;
                            position: absolute;
                            top: 48px;
                            left: 50%;
                            opacity: 0;
                            transform: translateX(-50%) translateY(-50%);
                            @include transition(200ms);
                        }
                        &:hover{
                            &:before{
                                opacity: 1;
                                top: 51px;
                            }
                        }
                    }
                }
            }
            @media screen and (max-width:$width - 1px){
                position: fixed;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background: rgba($normal,.96);
                z-index: 5000;
                @include flex();
                align-items: center;
                justify-content: center;
                padding: 15% 0;
                visibility: hidden;
                &.activate{
                    visibility: visible;
                }
                li{
                    width: 100%;
                    font-size: 18px;
                    text-align: center;
                    a{
                        color: #FFF;
                        display: block;
                        &:hover{
                            opacity: .64;
                        }
                        &:after{
                            display: block;
                            content: attr(data-eng);
                            font-size: 10px;
                            color: lighten($main, 15%);
                        }
                    }
                }
            }
        }
    }
    .contact{
        @media screen and (max-width:1200px){
            display: none;
        }
        a{
            display: block;
            padding: 12px 24px;
            background: $main;
            color: #FFF;
			min-height: 72px;
			@media screen and (max-width:$width - 1px){
				min-height: 1px;
			}
            &:hover{
                background: lighten($main, 4%);
            }
        }
        dl{
            line-height: 1.4;
            dt{
                font-size: 13px;
            }
            dd{
                font-size: 21px;
                font-weight: 700;
                i{
                    display: inline-block;
                    margin: 0 8px 0 0;
                    font-size: 16px;
                    transform: rotateY(180deg) translateY(-1px);
                }
            }
        }
    }
    .mail{
        position: absolute;
        top: 0;
        right: 0;
        width: $mail;
        height: 100%;
        background: $sub;
        @include flex();
        align-items: center;
        justify-content: center;
        &:hover{
             background: lighten($sub, 4%);
        }
        @media screen and (max-width:$width - 1px){
            display: none;
        }
        span{
            font-size: 12px;
            color: #FFF;
            i{
                display: block;
                width: 100%;
                text-align: center;
                font-size: 20px;
                margin: 0 0 6px;
            }
        }
    }
}