@charset "utf-8";

@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600;700&display=swap');

@import "module/reset";
@import "module/module";


/* BASE COLOR
------------------------------------------------------------*/
$normal:#3c342c;

$main: #967354;
$sub: #735437;
$impact: #549676;
$bg: url(/images/common/bg_tex.png);

$link:darken($impact, 8%);
$border:#eae9e8;


/* SITE SETTING
------------------------------------------------------------*/
$width:1024px;
$pc-head:72px;
$sp-head:60px;

$responsive:769px;

@media screen and (min-width:$width){
	@include default(
		$width, //サイト幅
		14px, //フォントサイズ
		$normal, //フォントカラー
		$link, //リンクカラー
		#FFF, //ボディ背景
		20px //パディング
	);
}

@media screen and (max-width:$width - 1px){
	@include default(
		auto, //サイト幅
		14px, //フォントサイズ
		$normal, //フォントカラー
		$link, //リンクカラー
		#FFF, //ボディ背景
		15px //パディング
	);
}

@media screen and (max-width:$responsive){
	@include default(
		auto, //サイト幅
		13px, //フォントサイズ
		$normal, //フォントカラー
		$link, //リンクカラー
		#FFF, //ボディ背景
		15px //パディング
	);
}


@import "common/header";
@import "common/footer";
@import "common/bread";
@import "page/index";
@import "page/privacy";
@import "page/reason";
@import "page/treatment";
@import "page/question";
@import "page/shop";
@import "page/contact";
@import "page/voice";
@import "page/price";
@import "page/alliance";
@import "page/sitemap";
@import "page/network";
@import "page/news";

body{
    &.windows{
        text-shadow: 0 0 0 rgba($normal,.72);
    }
}

#site-wrapper,
#site-footer{
    position: relative;
    top: $pc-head;
    left: 0;
    @media screen and (max-width:$width - 1px){
        top: $sp-head;
    }
}

#leftbnr{
    position: fixed;
    left: 20px;
    top: 50%;
    z-index: 1000;
    transform: translateY(-50%);
}

.site-module{
    &.wrap{
        padding: 80px 0;
        @media screen and (max-width:$width - 1px){
            padding: 48px 0;   
        }
        @media screen and (max-width:$responsive){
            padding: 32px 0;   
        }
        &--wave{
            position: relative;
            padding-bottom: 110px;
            @media screen and (max-width:$responsive){
                padding-bottom: 56px;   
            }
            &:before{
                content: '';
                width: 100%;
                height: 30px;
                position: absolute;
                left: 0;
                bottom: 0;
                background: url("../../images/common/white_wave.png") repeat-x center bottom;
                background-size: 1280px;
                @media screen and (max-width:$responsive){
                    background-size: 2560/3 px;
                    height: 20px;   
                }
            }
        }
        &.mid{
            padding-top: 64px;
            @media screen and (max-width:$responsive){
                padding-top: 32px;   
            }
        }
		&.hod{
            padding-top: 24px;
			padding-bottom: 24px;
            @media screen and (max-width:$responsive){
                padding-top: 16px;
                padding-bottom: 16px;
            }
			&:last-child{
				padding-bottom: 64px;
                @media screen and (max-width:$responsive){
                    padding-bottom: 32px;   
                }
			}
        }
    }
    &.bg{
        background: $bg;
        background-size: 80px;
    }
    h2.large{
        text-align: center;
        font-size: 30px;
        font-weight: 700;
        line-height: 1;
        margin: 0 0 40px;
        background: url("../../images/common/h_dotto.png") no-repeat center top;
        background-size: 40px;
        padding: 22px 0 0;
        @media screen and (max-width:$responsive){
            font-size: 22px;
            margin: 0 0 24px;
        }
        &:after{
            content: attr(data-eng);
            color: $impact;
            display: block;
            font-size: 13px;
            letter-spacing: .12em;
            margin: 14px 0 0;
            @media screen and (max-width:$responsive){
                font-size: 12px;   
            }
        }
        &.mb{
            margin: 0 0 24px;
        }
    }
	h3.bar{
		line-height: 1.2;
		border-left: #967354 4px solid;
		font-size: 18px;
		font-weight: 700;
		padding: 2px 0 0 10px;
		&:after{
			content: '';
			height: 1px;
			background: #eae9e8;
			display: block;
			margin: 6px 0 16px;
		}
	}
    .btnarea{
        margin: 32px 0 0;
        @media screen and (max-width:$responsive){
            margin: 20px 0 0;   
        }
        &.center{
            text-align: center;
        }
        a{
            display: inline-block;
            background: $main;
            border-radius: 4px;
            color: #FFF;
            font-size: 15px;
            text-align: center;
            padding: 11px 64px;
            font-weight: 700;
            @include transition(120ms);
            @media screen and (max-width:$width - 1px){
                display: block;
                padding: 12px 15px;
                margin: 0 0 20px;
                &:last-of-type{
                    margin: 0;
                }
            }
            &:hover{
                background: $sub;
            }
            &.arrow{
                position: relative;
                &:after{
                    content: "\f0a9";
                    font-family: "Font Awesome 5 Free";
                    font-weight: 900;
                    position: absolute;
                    top: 50%;
                    right: 20px;
                    transform: translateY(-50%);
                }
            }
        }
    }
    .contactbnr{
        margin: 40px 0 0;
        @media screen and (max-width:$responsive){
            margin: 20px 0 0;   
        }
        img{
            width: 100%;
        }
    }
	.contactbnr-practitioner{
        margin: 0;
        img{
            width: 100%;
        }
    }
}

#sidebar{
    position: fixed;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    z-index: 1000;
    border-radius: 6px 0 0 6px;
    overflow: hidden;
    @media screen and (max-width:$width - 1px){
        box-shadow: 0 0 16px rgba(#000,.16);
        position: absolute;
        top: $sp-head + 30px;
        transform: translateY(0);
        z-index: 500;
    }
    img{
        width: 44px;
        background: $main;
        @media screen and (max-width:$width - 1px){
            width: 40px;   
        }
        @media screen and (max-width:$responsive){
            width: 34px;   
        }
    }
}

.tableStyle {
  width:100%;
  border:1px solid $border;
  border-collapse: collapse;
    @media screen and (max-width:$responsive){
        display: block;
        thead,
        tbody{
            display: block;
            tr{
                display: block;
                th,
                td{
                    display: block;
                }
            }
        }
    }
  th {
      border:1px solid $border;
      padding:15px;
      vertical-align: middle; text-align: left;
      background: #f4f3f3;
  }
  td {
      border:1px solid $border;
      padding:15px;
      background: #ffffff;
  }
  @media screen and (max-width:768px){
      th,td {
          padding: 10px;
      }
  }
}

.overlay{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(#000,.72);
    top: 0;
    left: 100%;
    transition: left 600ms cubic-bezier(0.83, 0, 0.17, 1);
    z-index: 9999;
    &.active{
        left: 0;
    }
    &__box{
        width: 720px;
        max-height: 88vh;
        position: absolute;
        top: 50%;
        left: 50%;
        background: #FFF;
        border-radius: 6px;
        overflow: auto;
        transform: translateX(-50%) translateY(calc(-50% + 40px));
        opacity: 0;
        position: relative;
        @include transition(600ms, $delay:600ms);
        @media screen and (max-width:$width - 1px){
            width: 88vw;   
        }
        &.active{
            opacity: 1;
            transform: translateX(-50%) translateY(-50%);
        }
        &__close{
            background: $sub;
            width: 40px;
            height: 40px;
            color: #FFF;
            border-radius: 50%;
            @include flex();
            align-items: center;
            justify-content: center;
            position: absolute;
            top: 15px;
            right: 15px;
            z-index: 2;
            cursor: pointer;
            transition: transform 200ms;
            &:hover{
                transform: scale(1.1) rotate(90deg);
            }
        }
        &__inner{
            padding: 40px;
            @include flex();
            @media screen and (max-width:$width - 1px){
                padding: 32px;   
            }
            @media screen and (max-width:$responsive){
                display: block;
                padding: 20px;
            }
            &__map{
                width: 200px;
                @media screen and (max-width:$responsive){
                    width: 110px;
                    margin: 0 auto 20px;
                }
                img{
                    width: 100%;
                }
            }
            &__txt{
                width: calc(100% - 200px);
                padding: 0 0 0 40px;
                @media screen and (max-width:$responsive){
                    width: auto;
                    padding: 0;
                }
                dt{
                    font-weight: 700;
                    font-size: 22px;
                    color: $sub;
                    border-bottom: $border 1px solid;
                    padding: 0 0 8px;
                    margin: 0 0 16px;
                    @media screen and (max-width:$responsive){
                        font-size: 16px;   
                    }
                }
                dd{
                    text-align: justify;
                }
            }
        }
    }
}

.site-404{
    .txt{
        text-align: center;
    }    
}

.scale-enter-active, .scale-leave-active {
	transition: 600ms;
}
.scale-enter, .scale-leave-to {
	opacity: 0;
}