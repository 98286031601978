.site-network{
    .box{
        margin: 0 0 48px;
        @media screen and (max-width:$responsive){
            margin: 0 0 32px;   
        }
        &:last-of-type{
            margin: 0;
        }
        .list{
            @include flex();
            justify-content: flex-start;
            margin: 0 0 0 -16px;
            @media screen and (max-width:$responsive){
                margin: 0 0 0 -8px;    
            }
            li{
                width: 33.3%;
                padding: 0 0 0 16px;
                margin: 16px 0 0;
                @media screen and (min-width:$responsive + 1px){
                    &:nth-child(-n+3){
                        margin-top: 0;
                    }
                }
                @media screen and (max-width:$responsive){
                    width: 50%;
                    padding: 0 0 0 8px;
                    margin: 8px 0 0;
                    &:nth-child(-n+2){
                        margin-top: 0;
                    } 
                }
                a{
                    display: block;
                    border: $border 2px solid;
                    border-radius: 4px;
                    text-align: center;
                    color: $normal;
                    padding: 10px 15px;
                    background: #f8f8f8;
                    &:hover{
                        border-color: $impact;
                        background: #FFF;
                    }
                    i{
                        color: $impact;
                        display: inline-block;
                        margin: 0 8px 0 0;
                    }
                }
            }
        }
    }
}