.site-contact{
	&.first{
		.texter{
			text-align: center;
			font-size: 20px;
			padding: 0 0 16px;
            @media screen and (max-width:$width - 1px){
                font-size: 16px;   
            }
            @media screen and (max-width:$responsive){
                font-size: 14px;
                text-align: justify;
                br{
                    display: none;
                }
            }
		}
		.formStyle{
			border:none;
			border-collapse:collapse;
			width:100%;
			@include font();
			tbody{
				tr{
					th,td{
						padding:32px 4px;
						border-bottom:$border 1px solid;
						text-align:left;
						vertical-align:middle;
						@media only screen and (max-width:768px){
							padding:14px 2px;
						}
					}
					th{
						width:22%;
						text-align:left;
                        @media screen and (max-width:$width - 1px){
                            width: 240px;   
                        }
						@media only screen and (max-width:768px){
							width:100%;
							font-size:16px;
						}
						&.hiss{
							position:relative;
							top:0;
							left:0;
							&:before{
								content:'必須';
								display:inline-block;
								padding: 4px 12px;
								@media all and (-ms-high-contrast: none){
									padding: 6px 12px 4px 12px;
								}
								color:#FFF;
								background:#967354;
								position: absolute;
								top: 50%;
								right: 0;
								margin: -16px 0 0;
								border-radius: 4px;
								@media only screen and (max-width:768px){
									font-size:12px;
									margin:-14px 0 0;
								}
							}
						}
					}
					td{
						padding-left:40px;
						@media only screen and (max-width:768px){
							padding-left:0;
						}
						input[type=text],
						input[type=tel],
						input[type=email],
						textarea{
							display:block;
							background:#f2f2f2;
							border:none;
							border-radius: 4px;
							@include font();
							font-size:15px;
							width:100%;
							padding:8px;
							@include transition(160ms);
							&:focus{
								transform: scale(1.02);
								background:#FFF;
								box-shadow:0 0 12px rgba(0,0,0,.16);
							}
						}
						select{
							display:block;
							background:#f2f2f2;
							border:none;
							border-radius: 4px;
							@include font();
							font-size:15px;
							width:100%;
							padding:8px;
						}
						label{
							vertical-align:middle;
							input{
								display:inline-block;
								margin:0 4px 0 0;
							}
						}
						textarea{
							height:200px;
						}
					}
				}
			}
			@media only screen and (max-width:768px){
				display:block;
				tbody,tr,th,td{
					display:block;
				}
			}
		}
		.send{
			margin:32px 0 0;
			text-align:center;
			input{
				border:none;
				width:240px;
				height:48px;
				@include font();
				color:#FFF;
				font-size:20px;
				font-weight:700;
				background:darken($main,4%);
				cursor:pointer;
				&:hover{
					background:darken($main,8%);
				}
			}
		}
	}
	&.second {
		.txtC {
			text-align: center;
			font-size: 24px;
			margin: 0 0 24px;
			@media screen and (max-width:768px){
				font-size: 16px;
				line-height: 1.5;
			}
		}
		.flex {
			@include flex;
			>li {
				list-style: none;
				width: 49%;
				margin: 0 0 2%;
				background: #f2f2f2;
				padding: 30px;
				@media screen and (max-width:768px){
					width: 100%;
					padding: 20px;
				}
				dl {
					dt {
						font-size: 20px;
						border-bottom: solid 1px #e5e5e5;
						padding: 0 0 8px;
						margin: 0 0 12px;
						@media screen and (max-width:768px){
							font-size: 16px;
							font-weight: 700;
						}
						&:before {
							content: "▶";
							color: $main;
							display: inline-block;
							margin-right: 5px;
						}
					}
					dd {
						min-height: 56px;
						@media screen and (max-width:768px){
							min-height: inherit;
						}
					}
				}
				.btnArea {
					margin-top: 24px;
					@media screen and (max-width:768px){
						margin-top: 10px;
					}
					a {
						font-size: 16px;
					}
				}
			}
		}
	}
}
