.site-price{
	.pricebnr{
        margin: 40px 0;
        @media screen and (max-width:$responsive){
            margin: 0 0 24px;    
        }
        img{
            width: 100%;
        }
    }
	.priceview{
		background: lighten($main, 51%);
		border: $main 1px dashed;
		margin: 0 auto 40px;
		width: 640px;
		padding: 16px 24px;
		@include flex();
		align-items: center;
		@media screen and (max-width:$responsive){
			margin: 0 0 24px;
			padding: 10px 15px;
			width: auto;
			justify-content: flex-start;
		}
		dt{
			font-size: 20px;
			font-weight: 700;
			@media screen and (max-width:$responsive){
				font-size: 16px;
				margin: 0 15px 0 0;
			}
		}
		&__price{
			font-size: 20px;
			font-weight: 700;
			@media screen and (max-width:$responsive){
				font-size: 16px;	
			}
			strong{
				color: $sub;
				font-size: 30px;
				line-height: 1;
				@media screen and (max-width:$responsive){
					font-size: 20px;	
				}
			}
		}
		&__txt{
			font-size: 15px;
			width: 320px;
			@media screen and (max-width:$responsive){
				width: 100%;
				font-size: 13px;
			}
		}
	}
	
	h3.bar{
		line-height: 1.2;
		border-left: #967354 4px solid;
		font-size: 18px;
		font-weight: 700;
		padding: 2px 0 0 10px;
		&:after{
			content: '';
			height: 1px;
			background: #eae9e8;
			display: block;
			margin: 6px 0 16px;
		}
	}
    .menu{
		border: #f4f3f3 8px solid;
		padding: 20px;
		margin: 0 0 32px 0;
		background: #fff;
        @include flex();
        @media screen and (max-width:$responsive){
            padding: 15px;
            margin: 0 0 24px;
            display: block;
        }
        &.nmb{
            margin-bottom: 0;
        }
        @media screen and (max-width:$width - 1px){
            display: block;
            position: relative;
            &:after{
                content: '';
                clear: both;
                display: block;
            }
        }
        &__pic{
            width: 440px;
            @media screen and (max-width:$width - 1px){
                width: 320px;
                margin: 0 32px 0 0;
                float: left;
            }
            @media screen and (max-width:$responsive){
                float: none;
                width: 100%;
                margin: 0 0 16px;
            }
            img{
                width: 100%;
            }
        }
        &__txt{
            width: calc(100% - 440px);
            padding: 0 0 0 40px;
			position: relative;
            @media screen and (max-width:$width - 1px){
                width: auto;
                padding: 0;
            }
            dt{
                font-size: 24px;
                font-weight: 700;
                margin: 0 0 8px;
                @media screen and (max-width:$width - 1px){
                    font-size: 20px;   
                }
                @media screen and (max-width:$responsive){
                    font-size: 17px;   
                }
                i{
                    background: $main;
                    color: #fff;
                    display: inline-block;
                    border-radius: 4px;
                    width: 200px;
                    padding: 2px 0;
                    text-align: center;
                    margin: 0 0 8px 32px;
                    @media screen and (max-width:$width - 1px){
                        width: 160px;
                        margin: 0 0 0 24px;
                        font-size: 16px;
                    }
                    @media screen and (max-width:$responsive){
                        font-size: 15px;
                        width: 140px;
                        padding: 0;
                    }
                }
            }
            dd{
                text-align: justify;
                @media screen and (max-width:$width - 1px){
                    br{
                        display: none;
                    }   
                }
                a{
                    text-decoration: underline;
                    font-weight: 700;
                }
            }
			&__badge{
				position: absolute;
				top: 0;
				right: 0;
				background: $impact;
				color: #FFF;
				padding: 8px 16px;
				font-size: 17px;
				font-weight: 700;
				border-radius: 6px;
				@media screen and (max-width:$width - 1px){
					font-size: 16px;
					padding: 2px 12px;
					top: -40px;
					right: -12px;
				}
			}
            &__list{
                margin: 24px 0 0;
                padding: 24px 0 0;
                border-top: $border 1px solid;
                @media screen and (max-width:$width - 1px){
                    clear: both;
                    border-top: none;
                    margin: 0;
                }
                @media screen and (max-width:$responsive){
                    padding: 15px;
                    background: #f2f2f2;
                    border-radius: 4px;
                    margin: 16px 0 0;
                }
                p{
                    margin: 0 0 12px;
                    font-weight: 700;
                }
                li{
                    width: 33.3%;
                    float: left;
                    font-size: 15px;
                    position: relative;
                    padding: 0 0 0 20px;
                    @media screen and (max-width:$responsive){
                        width: auto;
                        float: none;
                        font-size: 14px;
                    }
                    &:before{
                        content: '';
                        width: 12px;
                        height: 12px;
                        border: $main 4px solid;
                        border-radius: 50%;
                        position: absolute;
                        top: 50%;
                        left: 0;
                        transform: translateY(-50%);
                    }
                }
            }
        }
    }
}