.site-question{
    .answers{
        li{
            list-style: none;
            margin: 0 0 24px;
            &:last-child{
                margin: 0;
            }
            dl{
                background: #FFF;
                padding: 0 32px;
                box-shadow: 0 1px 6px rgba(#000,.08);
				border: $border 1px solid;
                @include transition(200ms);
				@media screen and (max-width:$responsive){
					padding: 0 15px;
				}
                dt,dd{
                    padding: 24px 0;
                    padding-left: 32px;
                    position: relative;
                    top: 0;
                    left: 0;
                    font-size: 15px;
					@media screen and (max-width:$responsive){
						padding: 15px 0;
						padding-left: 32px;
						font-size: 13px;
					}
                    &:before{
                        position:absolute;
                        left: 0;
                        top: 20px;
                        color: $link;
                        font-size: 20px;
                        font-weight: 700;
						@media screen and (max-width:$responsive){
							top: 8px;
						}
                    }
                }
                dt{
                    font-weight: 700;
                    background-size: 20px;
                    padding-right: 32px;
                    &:before{
                        content: 'Q';
                    }
                }
                dd{
                    border-top:$border 1px solid;
                    &:before{
						color: #f65f6a;
                        content: 'A';
                    }
                }
            }
        }
    }
}