#site-footer{
	a{
		color:$normal;	
	}
    .bnrarea{
        background: #FFF;
        border-top: $border 1px solid;
        border-bottom: $border 1px solid;
        .display{
            padding: 24px 20px;
            border-left: $border 1px solid;
            border-right: $border 1px solid;
			@include flex();
			align-items: stretch;
            @media screen and (min-width:$responsive + 1px){
				ul.bnrleft,
				ul.bnrright{
					@include flex();
					transform: translateX(-10px);
					justify-content: flex-start;
					li{
						padding: 0 0 0 20px;
						a{
							img{
								width: 100%;
							}
						}
					}
				}
				
				ul.bnrleft{
					width: 40%;
					> li{
						width: 50%;
					}
				}
				ul.bnrright{
					width: 60%;
					>li{
						width: 33.3%;
					}
				}
            }
			@media screen and (max-width:$responsive){
				padding: 15px;  
				ul.bnrleft,
				ul.bnrright{
					li{
						width: 100%;
						a{
							display: block;
							width: 100%;
						}
						img{
							width: 100%;
						}
					}	
				}

				ul.bnrleft{
					width: calc(50% - 7.5px);
					li{
						margin: 0 0 15px;
						&:last-child{
							margin: 0;
						}
					}
				}
				ul.bnrright{
					width: calc(50% - 7.5px);
					@include flex();
					align-items: stretch;
					li{
						@include flex();
						align-items: center;
						&:first-child{
							align-items: flex-start;
						}
						&:last-child{
							align-items: flex-end;
						}
					}
				}
        	}
		}
    }
    .map{
        width: 100%;
        height: 300px;
        @media screen and (max-width:$width - 1px){
            height: 200px;   
        }
        iframe{
            width: 100%;
            height: 100%;
            vertical-align: bottom;
            filter: grayscale(1);
        }
    }
    .box{
        background: $normal;
        color: #FFF;
        padding: 64px 0;
        @media screen and (max-width:$width - 1px){
            padding: 48px 0;   
        }
        a{
            color: #FFF;
        }
        .profile{
            padding: 0 0 40px;
            margin: 0 0 40px;
            border-bottom: rgba(#FFF,.12) 1px solid;
            @include flex();
            justify-content: flex-start;
            position: relative;
            @media screen and (max-width:$width - 1px){
                display: block;
                padding: 0;
                margin: 0 0 32px;
                border: none;
            }
            &__shop{
                background: url("../../../images/common/mark.png") no-repeat left center;
                background-size: 56px;
                padding: 0 0 0 72px;
                padding-right: 32px;
                margin: 0 32px 0 0;
                border-right: rgba(#FFF,.12) 1px solid;
                @media screen and (max-width:$width - 1px){
                    padding-right: 0;
                    margin: 0 0 16px;
                    border: none;
                }
                dt{
                    font-size: 11px;
                }
                dd{
                    font-weight: 700;
                    font-size: 18px;
                }
            }
            &__tel{
                
            }
            &__btn{
                position: absolute;
                right: 0;
                top: 8px;
                border: rgba(#FFF, .8) 1px solid;
                padding: 8px 48px;
                color: #FFF;
                font-size: 15px;
                @media screen and (max-width:$responsive){
                    position: relative;
                    display: block;
                    text-align: center;
                    padding: 10px 15px;
                    margin: 16px 0 0;
                }
            }
        }
        .fnav{
            @include flex();
            justify-content: center;
            @media screen and (max-width:$width - 1px){
                justify-content: space-between;   
            }
            li{
                list-style: none;
                font-size: 12px;
                @media screen and (max-width:$width - 1px){
                    width: 23.5%;
                    margin: 2% 0 0;
                    &:nth-child(-n+4){
                        margin-top: 0;
                    }
                    a{
                        display: block;
                        text-align: center;
                        background: rgba(#FFF,.12);
                        border-radius: 4px;
                        padding: 10px 15px;
                    }   
                }
                @media screen and (max-width:$responsive){
                    width: calc(50% - 5px);
                    margin: 0 0 10px;
                    &:nth-last-child(-n+2){
                        margin: 0;
                    }
                }
                &:after{
                    content: '|';
                    margin: 0 20px;
                    opacity: .16;
                    display: inline-block;
                    @media screen and (max-width:$width - 1px){
                        display: none;
                    }
                }
                &:last-child{
                    &:after{
                        display: none;
                    }
                }
            }
        }
        small{
            font-size: 10px;
            text-align: center;
            display: block;
            margin: 24px 0 0;
        }
    }
}