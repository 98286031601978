.site-index{
    .firstview{
        height: 56vh;
        min-height: 560px;
        position: relative;
        overflow: visible;
        @media screen and (max-width:$width - 1px){
            height: 400px;
            min-height: 1px;
        }
        @media screen and (max-width:$responsive){
            height: 240px;   
        }
        &.active{
            .firstview__container{
                .firstview__container__ttl{
                    opacity: 1;
                    span{
                        @for $i from 1 through 50{
                            &:nth-of-type(#{$i}){
                                opacity: 1;
                                transform: translateY(0) scale(1);
                                @include transition(500ms, $delay: 60ms * $i);
                            }
                        }
                    }
                }
            }
        }
        #fvswipe{
            opacity: 0;
            transition: opacity 800ms;
            &.swiper-container-initialized{
                opacity: 1;
            }
        }
        .sns{
            position: absolute;
            top: 24px;
            right: 24px;
            $size: 40px;
            width: $size;
            height: $size;
            @include flex();
            align-items: center;
            justify-content: center;
            font-size: 16px;
            border-radius: 50%;
            background: #FFF;
            z-index: 100;
            color: $main;
            @include transition(120ms);
            @media screen and (max-width:$width - 1px){
                display: none;
            }
            &:hover{
                transform: scale(1.2);
            }
        }
        &__container{
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: 10;
            @include flex();
            justify-content: center;
            align-items: center;
            //background: rgba(#000,.08);
            &__ttl{
                color: #FFF;
                font-size: 72px;
                letter-spacing: .08em;
                font-weight: 700;
                text-align: center;
                line-height: 1.26;
                opacity: 0;
                @media screen and (max-width:$width - 1px){
                    font-size: 48px;   
                }
                @media screen and (max-width:$responsive){
                    font-size: 28px;
                    transform: translateY(-8px);
                }
                span{
                    display: inline-block;
                    opacity: 0;
                    transform: translateY(-40px) scale(2);
                }
            }
        }
        .fvswipe{
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            .bg{
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background-size: cover;
                background-position: center;
            }
            .swiper-slide-active .bg,
            .swiper-slide-duplicate-active .bg,
            .swiper-slide-prev .bg{
                animation: zoomUp 8s linear 0s 1 normal both;  
            }
            @keyframes zoomUp {
                0% {
                    transform: scale(1.08);
                }
                100% {
                    transform: scale(1);
                }
            }
        }
        &__news{
            position: absolute;
            left: 0;
            bottom: 0;
            width: 100%;
            transform: translateY(50%);
            z-index: 100;
            .display{
                background: #FFF;
                @include flex();
                align-items: center;
                padding: 0;
                box-shadow: 0 0 32px rgba($main,.04);
                @media screen and (max-width:$responsive){
                    display: block;
                }
            }
            &__txt{
                width: calc(100% - 180px);
                padding: 0 40px 0 0;
                @media screen and (max-width:$responsive){
                    width: auto;   
                }
                a{
                    padding: 15px 20px;
                    @include flex();
                    color: $normal;
                    align-items: center;
                    position: relative;
                    @media screen and (max-width:$responsive){
                        padding: 15px;   
                    }
                    &:hover{
                        span{
                            text-decoration: underline;
                        }
                    }
                    i{
                        position: absolute;
                        top: 50%;
                        right: -10px;
                        transform: translateY(-50%) translateX(50%);
                        opacity: .2;
                    }
                    time{
                        width: 100px;
                        background: $sub;
                        color: #FFF;
                        font-size: 12px;
                        text-align: center;
                    }
                    span{
                        width: calc(100% - 100px);
                        padding: 0 0 0 20px;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                    }
                }
            }
            &__more{
                width: 180px;
                padding: 15px 0;
                text-align: center;
                background: $main;
                color: #FFF;
                @media screen and (max-width:$responsive){
                    display: none;
                }
                &:hover{
                    background: lighten($main, 8%);
                }
            }
        }
    }
    
    .firstsec{
        h1{
            text-align: center;
            font-size: 36px;
            font-weight: 700;
            line-height: 1;
            margin: 16px 0 40px;
            @media screen and (max-width:$responsive){
                font-size: 24px;
                margin-bottom: 32px;
            }
            small{
                display: block;
                font-size: 16px;
                margin: 0 0 15px;
                color: $sub;
                @media screen and (max-width:$responsive){
                    font-size: 14px;
                    margin: 0 0 10px;
                }
            }
            strong{
                color: $impact;
            }
        }
        &__flex{
            @include flex();
            @media screen and (max-width:$responsive){
                display: block;   
            }
        }
        &__left{
            width: 140px;
            @media screen and (max-width:$responsive){
                display: none;
            }
        }
        &__right{
            width: calc(100% - 140px);
            padding: 0 0 0 48px;
            transform: translateY(2px);
            @media screen and (max-width:$responsive){
                width: auto;
                padding: 0;
                transform: translateY(0);
            }
        }
        &__videos{
            @include flex();
            @media screen and (max-width:$responsive){
                display: block;   
            }
            > li{
                width: calc(50% - 12px);
                border-radius: 4px;
                overflow: hidden;
				@media screen and (min-width:$responsive + 1px){
					margin: 16px 0 0;
					&:nth-child(-n+2){
						margin: 0;
					}
				}
                @media screen and (max-width:$responsive){
                    width: auto;
                    margin: 16px 0 0;
                    &:first-child{
                        margin: 0;
                    }
                }
                iframe{
                    vertical-align: bottom;
                    width: 100%;
                    height: 228px;
                    @media screen and (max-width:$width - 1px){
                        height: 180px;   
                    }
                    @media screen and (max-width:$responsive){
                        height: 200px;   
                    }
                }
            }
        }
        .bnr{
            @include flex();
            @media screen and (max-width:$width - 1px){
                display: block;   
            }
            @media screen and (max-width:$responsive){
            }
            > li{
                margin: 0 0 24px;
                &:last-child{
                    margin: 0;
                }
                @media screen and (max-width:$responsive){
                    margin: 0 0 16px;   
                }
                img{
                    width: 100%;
                }
            }
        }
    }
    
    .secondsec{
        position: relative;
        &__reason{
            @include flex();
            position: relative;
            &:before,
            &:after{
                content: '';
                width: 1px;
                height: 100%;
                position: absolute;
                top: 0;
                left: 32%;
                background: $border;
                @media screen and (max-width:$width - 1px){
                    left: 32.5%;   
                }
                @media screen and (max-width:$responsive){
                    display: none;
                }
            }
            &:after{
                left: auto;
                right: 32%;
                @media screen and (max-width:$width - 1px){
                    right: 32.5%;   
                }
            }
            li{
                list-style: none;
                width: 28%;
                padding-bottom: 48px;
                position: relative;
                @media screen and (max-width:$width - 1px){
                    width: 30%;   
                }
                @media screen and (max-width:$responsive){
                    width: auto;
                    padding-bottom: 20px;
                    margin: 0 0 20px;
                    border-bottom: $border 1px solid;
                    &:last-child{
                        margin: 0;
                        padding: 0;
                        border: none;
                    }
                }
                i{
                    width: 48px;
                    height: 48px;
                    text-align: center;
                    @include flex();
                    align-items: center;
                    justify-content: center;
                    color: #FFF;
                    background: $impact;
                    font-weight: 700;
                    font-size: 18px;
                    position: absolute;
                    top: -8px;
                    left: -8px;
                    z-index: 2;
                    @media screen and (max-width:$responsive){
                        width: 40px;
                        height: 40px;
                    }
                    &:before{
                        content: '';
                        width: 100%;
                        height: 100%;
                        position: absolute;
                        border: $impact 1px solid;
                        top: 4px;
                        left: 4px;
                        z-index: -1;
                    }
                }
                figure{
                    img{
                        width: 100%;
                    }
                }
                dl{
                    margin: 20px 0;
                    @media screen and (max-width:$responsive){
                        margin-bottom: 0;   
                    }
                    dt{
                        font-weight: 700;
                        font-size: 20px;
                        text-align: center;
                        &:before{
                            content: attr(data-eng);
                            color: $sub;
                            display: block;
                            text-transform: uppercase;
                            font-size: 10px;
                            line-height: 1;
                            margin: 0 0 2px;
                        }
                    }
                    dd{
                        padding: 6px 0 0;
                        text-align: justify;
                    }
                    .btnarea{
                        margin: 0;
                        @media screen and (max-width:$responsive){
                            margin: 20px 0 0;   
                        }
                    }
                }
                a{
                    display: block;
                    position: absolute !important;
                    left: 0;
                    bottom: 0;
                    display: block;
                    width: 100%;
                    @media screen and (max-width:$responsive){
                        position: relative !important;
                    }
                }
            }
        }
    }
    
    .thirdsec{
        position: relative;
        &:before{
            content: '';
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            background: url("../../../images/common/voice_bg.png") no-repeat center 160px;
            opacity: .64;
        }
        .context{
            text-align: center;
            font-size: 14px;
            font-weight: 700;
            margin: 0 0 32px;
            @media screen and (max-width:$responsive){
                margin: 0 15px 24px;
            }
        }
        #voiceswipe{
            @media screen and (max-width:$responsive){
                padding: 0 15px;   
            }
        }
        .swiper-wrapper{
            transition-timing-function: cubic-bezier(0.85, 0, 0.15, 1);
            .swiper-slide{
                transform: scale(.9);
                transition: transform 400ms cubic-bezier(0.85, 0, 0.15, 1);
            }
            .swiper-slide-active{
                transform: scale(1);
            }
        }
        &__list{
            @include flex();
            background: #FFF;
            padding: 24px;
            margin: 16px 0;
            width: 720px;
            box-shadow: 0 4px 16px rgba($normal,.12);
            position: relative;
            @media screen and (max-width:$width - 1px){
                width: calc(100% - 30px);
                margin-bottom: 0;
                padding: 15px;
            }
            &:before{
                content: '';
                width: 160px;
                height: 32px;
                background: $normal;
                opacity: .06;
                position: absolute;
                top: -16px;
                left: 50%;
                transform: translateX(-50%);
            }
            figure{
                width: 140px;
                @media screen and (max-width:$responsive){
                    width: 100px;   
                }
                img{
                    width: 100%;
                }
                figcaption{
                    text-align: center;
                    margin: 10px 0 0;
                    font-weight: 700;
                }
            }
            dl{
                width: calc(100% - 140px);
                padding: 0 0 0 32px;
                @media screen and (max-width:$responsive){
                    width: calc(100% - 100px);
                    padding: 0 0 0 20px;
                }
                dt{
                    font-size: 18px;
                    font-weight: 700;
                    margin: 0 0 12px;
                    padding: 0 0 10px;
                    border-bottom: $border 1px solid;
                    @media screen and (max-width:$responsive){
                        font-size: 15px;   
                    }
                    i{
                        display: inline-block;
                        transform: rotateY(180deg);
                        margin: 0 8px 0 0;
                        color: $impact;
                    }
                }
                dd{
                    max-height: 80px;
                    overflow: auto;
                    padding: 0 24px 0 0;
                    text-align: justify;
                    @media screen and (max-width:$responsive){
                        padding: 0;
                        max-height: 72px;
                    }
                }
            }
        }
        .bnr{
            margin: 32px auto 0;
            text-align: center;
            @media screen and (max-width:$width - 1px){
                img{
                    width: 100%;
                } 
            }
            @media screen and (max-width:$responsive){
                margin: 20px 0 0;   
            }
        }
    }
    
    .forsec{
        .greeting{
            @include flex();
            @media screen and (max-width:$width - 1px){
                display: block;   
            }
            &__pic{
                width: 440px;
                @media screen and (max-width:$width - 1px){
                    width: 320px;
                    float: left;
                    margin: 0 32px 0 0;
                }
                @media screen and (max-width:$responsive){
                    width: auto;
                    float: none;
                    margin: 0 0 16px;
                }
                img{
                    width: 100%;
                }
            }
            &__txt{
                width: calc(100% - 440px);
                padding: 0 0 0 40px;
                @media screen and (max-width:$width - 1px){
                    width: auto;
                    padding: 0;
                }
                dt{
                    font-size: 24px;
                    font-weight: 700;
                    margin: 0 0 8px;
                    @media screen and (max-width:$responsive){
                        font-size: 17px;   
                    }
                }
                dd{
                    text-align: justify;
                    a{
                        text-decoration: underline;
                        font-weight: 700;
                    }
                }
                &__list{
                    margin: 24px 0 0;
                    padding: 24px 0 0;
                    border-top: $border 1px solid;
                    @media screen and (max-width:$width - 1px){
                        margin: 0;
                        border: none;
                        clear: both;
                    }
                    @media screen and (max-width:$responsive){
                        margin: 24px 0 0;
                        padding: 15px; 
                        background: #f2f2f2;
                        border-radius: 4px;
                    }
                    p{
                        margin: 0 0 12px;
                        font-weight: 700;
                    }
                    li{
                        width: 33.3%;
                        float: left;
                        font-size: 15px;
                        position: relative;
                        padding: 0 0 0 20px;
                        @media screen and (max-width:$responsive){
                            width: auto;
                            float: none;
                            margin: 0 0 4px;
                            padding: 0 0 0 15px;
                            &:last-child{
                                margin: 0;
                                padding-bottom: 0;
                            }
                        }
                        &:before{
                            content: '';
                            width: 12px;
                            height: 12px;
                            border: $main 4px solid;
                            border-radius: 50%;
                            position: absolute;
                            top: 50%;
                            left: 0;
                            transform: translateY(-50%);
                        }
                    }
                }
            }
        }
        .other{
            @include flex();
            margin: 40px 0 0;
            @media screen and (max-width:$responsive){
                margin: 24px 0 0;
                display: block;
            }
            &__item{
                width: calc(50% - 16px);
                border: lighten($border, 4%) 8px solid;
                padding: 20px;
                @media screen and (max-width:$width - 1px){
                    width: calc(50% - 12px);
                    padding: 15px;
                }
                @media screen and (max-width:$responsive){
                    width: auto;
                    padding: 15px;
                    margin: 0 0 20px;
                    &:last-of-type{
                        margin: 0;
                    }
                }
                dt{
                    line-height: 1.2;
                    border-left: $main 4px solid;
                    font-size: 18px;
                    font-weight: 700;
                    padding: 2px 0 0 10px;
                    &:after{
                        content: '';
                        height: 1px;
                        background: $border;
                        display: block;
                        margin: 6px 0 16px;
                    }
                }
                dd{
                    &.btnarea{
                        margin: 15px 0 0;
                    }
                }
            }
        }
    }
	.news{
        position: relative;
        z-index: 2;
		background: $normal;
		color: #FFF;
        &.wrap{
            padding: 80px 0;
            @media screen and (max-width:$width - 1px){
                padding: 32px 0;   
            }
        }
		.display{
			&.flex{
				@include flex();
				@media screen and (max-width:$responsive){
					display: block;	
				}
			}
		}
		.newsbox{
			width: 100%;
			@include flex();
			@media screen and (max-width:$responsive){
				width: 100%;
				padding: 0;
				display: block;	
			}
		}
        .leftside{
            width: 280px;
			@media screen and (max-width:$responsive){
				width: 100%;
			}
			h3{
				line-height: 1;
				font-size: 14px;
				margin: 0 0 16px;
				font-weight: 700;
				@media screen and (max-width:$responsive){
					font-size: 12px;	
				}
				&:before{
					content: attr(data-eng);
					font-size: 36px;
					display: inline-block;
					margin: 0 24px 0 0;
					@media screen and (max-width:$responsive){
						font-size: 20px;	
					}
				}
			}
			a{
				display: inline-block;
				font-weight: 700;
				color: #FFF;
				font-size: 15px;
				@media screen and (max-width:$responsive){
					position: absolute;
					top: 0;
					right: 15px;
					font-size: 13px;
				}
				&:hover{
					text-decoration: underline;
				}
				i{
					display: inline-block;
					font-size: 13px;
					margin: 0 0 0 12px;
				}
			}
        }
        ol{
            width: calc(100% - 280px);
            height: 130px;
            padding: 0 40px 0 0;
            overflow: auto;
			@media screen and (max-width:$responsive){
				width: 100%;
				height: 164px;
				padding: 15px;
				border: rgba(#FFF,.2) 1px solid;
				border-radius: 4px;
			}
            li{
                border-bottom: rgba(#FFF,.12) 1px solid;
                padding: 0 0 24px;
                margin: 0 0 24px;
				@media screen and (max-width:$responsive){
					margin: 0 0 15px;
					padding: 0 0 15px;
				}
                &:last-child{
                    margin: 0;
                }
                a{
                    @include flex();
                    align-items: center;
                    color: #FFF;;
					@media screen and (max-width:$responsive){
						display: block;
					}
                    &:hover{
                        h4{
                            text-decoration: underline;
                        }
                    }
                }
                time,.category{
                    display: block;
                    font-weight: 600;
					@media screen and (max-width:$responsive){
						float: left;
					}
                }
                time,
                .category{
                    width: 120px;
                    font-size: 12px;
                    background: lighten($normal, 8%);
                    color: #FFF;
                    padding: 2px 0;
                    text-align: center;
                    font-weight: 500;
                    @media screen and (max-width:$width - 1px){
                        width: 100px;   
                    }
					@media screen and (max-width:$responsive){
						width: 80px;
						font-size: 10px;
						padding: 1px 12px;
						margin: 2px 0 0;
					}
                }
                .category{
                    background: $main;
                    &.str{
                        background: #ab132b;
                    }
                    @media screen and (max-width:$responsive){
                        margin: 0 0 0 12px;   
                    }
                }
                h4{
                    width: calc(100% - 240px - 40px);
                    font-size: 16px;
                    padding: 0 0 0 32px;
                    @media screen and (max-width:$width - 1px){
                        width: calc(100% - 200px - 40px);
                    }
					@media screen and (max-width:$responsive){
						width: 100%;
						font-size: 12px;
						padding: 8px 0 0;
						clear: both;
					}
                }
            }
        }
    }

}