.site-privacy{
    .privacybox{
        li{
            list-style: inside;
        }
        &__data{
            padding: 25px;
            background: lighten($border, 6%);
            border: $border 8px solid;
			margin: 25px 0 0;
            @media screen and (max-width:$responsive){
                padding: 15px;   
            }
            dt{
                font-size: 20px;
                text-align: center;
                font-weight: 700;
                margin: 0 0 16px;
                @media screen and (max-width:$responsive){
                    font-size: 15px;
                    margin: 0 0 8px;
                }
            }
            dd{
                text-align: justify;
                padding: 0 4%;
                @media screen and (max-width:$responsive){
                    padding: 0 15px;   
                }
            }
        }
    }
}