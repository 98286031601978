.site-shop{
	.context{
        text-align: center;
        font-size: 16px;
        font-weight: 700;
        margin: 0 0 32px;
        border: #f4f3f3 8px solid;
        padding: 20px;
        @media screen and (max-width:$responsive){
            padding: 15px;
            font-size: 14px;
            margin: 0 0 24px;
        }
    }
	.oneclum{
        position: relative;
        &:before{
            content: "";
            width: 2px;
            height: 100%;
            background: #EEE;
            position: absolute;
            top: 20px;
            left: 168px;	
        }	
		> li{
			list-style: none;
			padding: 12px 0;
			position: relative;
			&:before{
				content: "●";
				color: $impact;
				display: block;
				font-size: 18px;
				position: absolute;
				top: 10px;
				left: 160px;
			}
		}
        dl{
            font-size: 16px;
            font-weight: 700;
            dt{
                width: 10%;
                text-align: right;
            }
            dd{
                width: 80%;
            }	
        }
    }
	.historyList{
		border: #f4f3f3 8px solid;
		padding: 20px;
        @media screen and (max-width:$responsive){
            padding: 15px;   
        }
		li{
			position: relative;
			padding: 0 0 20px;
			overflow: hidden;
            @media screen and (max-width:$responsive){
                padding: 0 0 15px;
                margin: 0 0 15px;
                border-bottom: $border 1px solid;
                &:last-child{
                    margin: 0;
                    padding: 0;
                    border: none;
                }
            }
            &:before{
				content: '';
				width: 12px;
				height: 12px;
				background: $border;
				position: absolute;
				top: 14px;
				left: 14%;
				border-radius: 50%;
				@media screen and (max-width:$responsive){
					display: none;
				}
			}
			&:after{
				content: '';
				width: 2px;
				height: 100%;
				background: $border;
				position: absolute;
				top: 0;
				left: calc(14% + 5px);
				@media screen and (max-width:$responsive){
					display: none;
				}
			}
			dl{
				@include flex();
				align-items:flex-start;
				@media screen and (max-width:$responsive){
					display: block;	
				}
				dt{
					width: 20%;
					font-size: 16px;
					padding: 8px 0 0;
					font-weight: bold;
					color: $impact;
					@media screen and (max-width:$responsive){
						width: auto;
						font-size: 16px;
						font-weight: 700;
					}
				}
				dd{
					width: 80%;
					padding: 8px 0 0;
					font-size: 15px;
					@media screen and (max-width:$responsive){
						width: auto;
                        padding: 0;
						font-size: 13px;
					}
				}
			}
		}
	}
	.clearfix{
		background: $bg;
        background-size: 80px;
		padding: 32px;
        @media screen and (max-width:$responsive){
            padding: 15px;
            border-radius: 4px;
        }
		li{
        	width: 33.3%;
            float: left;
            font-size: 15px;
            position: relative;
            padding: 0 0 0 20px;
            @media screen and (max-width:$width - 1px){
                width: 50%;   
            }
            @media screen and (max-width:$responsive){
                width: auto; 
                float: none;
                font-size: 14px;
                margin: 0 0 4px;
                &:last-child{
                    margin: 0;
                }
            }
            &:before{
                content: '';
                width: 12px;
                height: 12px;
                border: $main 4px solid;
                border-radius: 50%;
                position: absolute;
                top: 50%;
                left: 0;
                transform: translateY(-50%);
            }
        }
	}
	.opponent {
    	width:100%;
        border-collapse: collapse;
        @media screen and (max-width:$responsive){
            display: block;
            tbody,
            thead{
                display: block;
                tr{
                    display: block;
                    th,td{
                        display: block;
                    }
                    td{
                        margin: 0 0 16px;
                        &:last-of-type{
                            margin: 0;
                        }
                    }
                }
            }
        }
        th {
			background: $impact;
    		color: #FFF;
    		padding: 2px 0;
    		width: 120px;
    		border-radius: 3px;
    		margin: 0 0 4px;
    		font-weight: 700;
			vertical-align: middle;
            @media screen and (max-width:$responsive){
                width: auto;
                display: block;
                padding: 8px 15px;
                margin: 0;
                border-radius: 4px 4px 0 0;
            }
        }
        td {
            padding: 16px 32px 16px 16px;
            @media screen and (max-width:$responsive){
                padding: 15px;
                border: $impact 1px solid;
                border-top: none;
                border-radius: 0 0 4px 4px;
            }
        }
	}
}
