.site-voice{
	.context{
            text-align: center;
            font-size: 14px;
            font-weight: 700;
            margin: 0 0 32px;
    }
	.bnr{
            margin: 64px auto 0;
            text-align: center;
    }
    .voicebox{
        position: relative;
        &:before{
            content: '';
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            background: url("../../../images/common/voice_bg.png") no-repeat center 160px;
            opacity: .64;
        }
        .swiper-wrapper{
            transition-timing-function: cubic-bezier(0.85, 0, 0.15, 1);
            .swiper-slide{
                transform: scale(.9);
                transition: transform 400ms cubic-bezier(0.85, 0, 0.15, 1);
            }
            .swiper-slide-active{
                transform: scale(1);
            }
        }
        &__list{
            @include flex();
            background: #FFF;
            padding: 24px;
            margin: 16px 0;
            width: 100%;
            box-shadow: 0 4px 16px rgba($normal,.12);
            position: relative;
            figure{
                width: 140px;
                img{
                    width: 100%;
                }
                figcaption{
                    text-align: center;
                    margin: 10px 0 0;
                    font-weight: 700;
                }
            }
            dl{
                width: 100%;
                padding: 0 32px;
                dt{
                    font-size: 18px;
                    font-weight: 700;
                    margin: 0 0 12px;
                    padding: 0 0 10px;
                    border-bottom: $border 1px solid;
                    i{
                        display: inline-block;
                        transform: rotateY(180deg);
                        margin: 0 8px 0 0;
                        color: $impact;
                    }
                }
                dd{
                    max-height: 100px;
                    overflow: auto;
                    padding: 0 24px 0 0;
                    text-align: justify;
                }
            }
        }
    }
}