.site-alliance{
    .alliance-profile{
        @include flex();
        @media screen and (max-width:$responsive){
            display: block;   
        }
        &__pic{
            width: 440px;
            @media screen and (max-width:$responsive){
                width: 100%;   
            }
            img{
                width: 100%;
            }
        }
        &__txt{
            width: calc(100% - 440px);
            padding: 0 0 0 40px;
            @media screen and (max-width:$responsive){
                width: auto;
                padding: 20px 0 0;
            }
            dt{
                font-size: 24px;
                font-weight: 700;
                margin: 0 0 8px;
                @media screen and (max-width:$responsive){
                    font-size: 18px;   
                }
            }
            dd{
                text-align: justify;
                a{
                    text-decoration: underline;
                    font-weight: 700;
                }
            }
        }
    }
}