.secondary{
    position: relative;
    height: 280px;
    @include flex();
    align-items: center;
    justify-content: center;
    margin: 0 0 24px;
    background-size: cover;
    background-position: center;
    @media screen and (max-width:$responsive){
        height: 230px;
    }
    &:before{
        content: '';
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background: darken($normal, 20%);
        opacity: .32;
    }
    h1{
        position: relative;
        z-index: 2;
        color: #FFF;
        font-weight: 700;
        text-align: center;
        font-size: 16px;
        line-height: 1;
        transform: translateY(-10px);
        letter-spacing: .08em;
        @media screen and (max-width:$responsive){
            font-size: 15px;   
        }
        &:before{
            content: attr(data-eng);
            font-size: 40px;
            display: block;
            margin: 0 0 16px;
            @media screen and (max-width:$responsive){
                font-size: 28px;
                margin: 0 0 8px;
            }
        }
    }
    &__bread{
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        transform: translateY(50%);
        ol{
            background: #FFF;
            box-shadow: 0 0 16px rgba(#000,.12);
            color: $normal;
            @include flex();
            align-items: center;
            justify-content: flex-start;
            padding: 12px 20px;
            font-size: 13px;
            @media screen and (max-width:$responsive){
                padding: 12px 15px;
                font-size: 11px;
            }
            a{
                color: $normal;
            }
            li{
                i{
                    color: $sub;
                    display: inline-block;
                    margin: 0 8px 0 0;
                }
                &:after{
                    content: '\f054';
                    font-family: "Font Awesome 5 Free";
                    font-weight: 900;
                    opacity: .32;
                    font-size: 8px;
                    display: inline-block;
                    margin: 0 16px;
                    transform: translateY(-2px);
                    @media screen and (max-width:$responsive){
                        margin: 0 10px;   
                    }
                }
                &:last-child{
                    &:after{
                        display: none;
                    }
                }
            }
        }
    }
}