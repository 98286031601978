.site-treatment{
	.context{
        text-align: center;
        font-size: 14px;
        font-weight: 700;
        margin: 0 0 32px;
    }
	.conlink{
            @include flex();
            margin: 40px 0 0;
            @media screen and (max-width:$responsive){
                margin: 0;
                display: block;
            }
            &__item{
                width: calc(50% - 16px);
                border: lighten($border, 4%) 8px solid;
                padding: 20px;
				margin-top: 34px;
                &:nth-child(-n+2){
                    margin-top: 0;
                }
                @media screen and (max-width:$width - 1px){
                    width: calc(50% - 8px);
                    padding: 15px;
                    margin-top: 16px;
                }
                @media screen and (max-width:$responsive){
                    width: auto;
                    margin-top: 16px !important;
                }
                dt{
                    line-height: 1.2;
                    border-left: $main 4px solid;
                    font-size: 18px;
                    font-weight: 700;
                    padding: 2px 0 0 10px;
                    @media screen and (max-width:$responsive){
                        line-height: 1.4;     
                    }
                    &:after{
                        content: '';
                        height: 1px;
                        background: $border;
                        display: block;
                        margin: 6px 0 16px;
                    }
                }
                dd{
                    &.btnarea{
                        margin: 15px 0 0;
                    }
                }
            }
        }
      .contactbnr{
          margin: 40px 0 0;
          img{
              width: 100%;
          }
      }
}