.site-reason{
	.picarea{
        margin: 40px 0 0;
		text-align: center;
		border: #f4f3f3 8px solid;
		background-color: #fff;
        img{
            width: 80%;
            @media screen and (max-width:$responsive){
                width: 100%;   
            }
        }
    }
	.con1{
        .reasonimg{
			margin: 32px 0;
            @include flex();
            @media screen and (max-width:$responsive){
                margin: 0 0 24px;
            }
            &__pic{
                width: 440px;
                @media screen and (max-width:$width - 1px){
                    width: 320px;
                }
                @media screen and (max-width:$responsive){
                    width: 100%;   
                }
                img{
                    width: 100%;
                }
            }
            &__txt{
                width: calc(100% - 440px);
                padding: 0 0 0 40px;
                @media screen and (max-width:$width - 1px){
                    width: calc(100% - 320px);
                    padding: 0 0 0 32px;
                }
                @media screen and (max-width:$responsive){
                    width: auto;
                    padding: 0;
                    margin: 24px 0 0;
                }
                dt{
                    font-size: 34px;
                    font-weight: 700;
					@include flex();
                    align-items: center;
                    justify-content: flex-start;
                    @media screen and (max-width:$responsive){
                        font-size: 17px;   
                    }
					> i{
                    	width: 60px;
                    	height: 60px;
                    	text-align: center;
                    	@include flex();
                    	align-items: center;
                    	justify-content: center;
                    	color: #FFF;
                    	background: $impact;
                    	font-weight: 700;
                    	font-size: 18px;
                    	position: absolute;
                    	z-index: 2;
                        @media screen and (max-width:$responsive){
                            width: 32px;
                            height: 32px;
                        }
               		}
					> p{
						margin: 0;
						padding-left: 80px;
						padding-right: 20px;
						font-family: '游明朝','Yu Mincho',YuMincho,'Hiragino Mincho Pro',serif;
						color: $impact;
                        @media screen and (max-width:$responsive){
                            padding-left: 40px;
                            padding-right: 12px;
                            font-size: 26px;
                        }
					}
					> strong{
                        color: $sub;
                        font-size: 16px;
						text-transform: uppercase;
                        @media screen and (max-width:$responsive){
                           font-size: 12px;
                        }
					}
                }
                dd{
                    text-align: justify;
                    margin: 16px 0 4px;
                    @media screen and (max-width:$responsive){
                        margin: 8px 0 2px;   
                    }
                    a{
                        text-decoration: underline;
                        font-weight: 700;
                    }
					p{
                        font-weight: 700;
                    }
                }
                &__list{
                    margin: 16px 0 0;
                    padding: 16px 0 0;
                    border-top: $border 1px solid;
                    @media screen and (max-width:$width - 1px){
                        text-align: justify;
                        br{
                            display: none;
                        }   
                    }
                    p{
                        margin: 0 0 12px;
                        font-weight: 700;
                    }
                    li{
                        width: 33.3%;
                        float: left;
                        font-size: 15px;
                        position: relative;
                        padding: 0 0 0 20px;
                        &:before{
                            content: '';
                            width: 12px;
                            height: 12px;
                            border: $main 4px solid;
                            border-radius: 50%;
                            position: absolute;
                            top: 50%;
                            left: 0;
                            transform: translateY(-50%);
                        }
                    }
                }
            }
        }
    }
}