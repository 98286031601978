.site-news{
    .clumbox{
        @include flex();
        @media screen and (max-width:$responsive){
            display: block;   
        }
        .leftside{
            width: calc(100% - 280px);
            padding: 0 0 0 48px;
            order: 2;
            @media screen and (max-width:$responsive){
                width: auto;
                padding: 0;
                margin: 0 0 32px;
                order: 1;
            }
        }
        .rightside{
            width: 280px;
            order: 1;
            @media screen and (max-width:$responsive){
                width: auto;
                order: 2;
            }
            .bnrarea{
                margin: 0 0 56px;
                @media screen and (max-width:$responsive){
                    margin: 0 0 32px;
                    @include flex();
                }
                li{
                    margin: 0 0 16px;
                    @media screen and (max-width:$responsive){
                        width: calc(50% - 8px);
                    }
                    &:last-child{
                        margin: 0;
                    }
                    img{
                        width: 100%;
                    }
                    &.large{
                        @media screen and (max-width:$responsive){
                            width: 100%;   
                        }
                    }
                    .link{
                        border: $main 2px solid;
                        color: $main;
                        font-weight: 700;
                        display: block;
                        font-size: 16px;
                        padding: 8px 15px;
                        text-align: center;
                        &:hover{
                            background: $main;
                            color: #FFF;
                        }
                    }
                }
            }
        }
    }
    .pager{
        text-align: center;
        margin: 32px 0 0;
        a,span{
            width: 32px;
            height: 32px;
            line-height: 32px;
            text-align: center;
            display: inline-block;
            color: #FFF;
            background: lighten($main, 16%);
        }
        span{
            background: $sub;
        }
        a{
            &:hover{
                background: lighten($main, 8%);
            }
        }
    }
    .newslist{
        li{
            margin: 0 0 16px;
            padding: 0 0 16px;
            border-bottom: $border 1px solid;
            list-style: none;
            @media screen and (max-width:$responsive){
                margin: 0 0 16px;
                padding: 0 0 16px;
            }
            &.large{
                margin: 0 0 24px;
                padding: 0 0 24px;
                @media screen and (max-width:$responsive){
                    margin: 0 0 16px;
                    padding: 0 0 16px;
                }
            }
            &:last-child{
                margin: 0;
                padding: 0;
                border: none;
            }
            a{
                color: $normal;
                position: relative;
                &:after{
                    content: '';
                    clear: both;
                    display: block;
                }
                &:hover{
                    h4{
                        text-decoration: underline;
                    }
                }
                time,
                .category{
                    width: 120px;
                    font-size: 12px;
                    background: lighten($normal, 8%);
                    color: #FFF;
                    padding: 2px 0;
                    text-align: center;
                    font-weight: 500;
                    float: left;
                    @media screen and (max-width:$width - 1px){
                        width: 100px;   
                    }
					@media screen and (max-width:$responsive){
						width: 80px;
						font-size: 10px;
						padding: 1px 12px;
						margin: 2px 0 0;
					}
                }
                .category{
                    background: $main;
                    margin: 0 0 0 12px;
                    &.str{
                        background: #ab132b;
                    }
                }
                h4{
                    width: 100%;
                    padding: 8px 0 0;
                    clear: both;
                    &.vertical{
                        width: calc(100% - 240px - 12px);
                        padding: 0 0 0 20px;
                        font-size: 16px;
                        font-weight: 700;
                        clear: none;
                        float: left;
                        @media screen and (max-width:$width - 1px){
                            width: 100%;
                            padding: 8px 0 0;
                        }
                    }
                }
            }
        }
    }
    .edit{
        font-size: 15px;
        line-height: 1.8;
        @media screen and (max-width:$width - 1px){
            font-size: 14px;   
        }
        @media screen and (max-width:$responsive){
            font-size: 13.5px;   
        }
        a{
            color: #ab132b;
            text-decoration: underline;
            &:hover{
                text-decoration: none;
            }
        }
        h3{
            font-size: 24px;
            font-weight: 700;
            border-top: $normal 2px solid;
            border-bottom: $normal 2px solid;
            font-weight: 700;
            padding: 8px 2px;
            margin: 0 0 20px;
            @media screen and (max-width:$width - 1px){
                font-size: 22px;   
            }
            @media screen and (max-width:$responsive){
                font-size: 20px;   
            }
        }
        h4{
            font-size: 22px;
            border-bottom: $border 2px solid;
            font-weight: 700;
            padding: 0 0 0 16px;
            margin: 0 0 16px;
            position: relative;
            @media screen and (max-width:$width - 1px){
                font-size: 20px;   
            }
            @media screen and (max-width:$responsive){
                font-size: 18px;   
            }
            &:before{
                content: '';
                width: 4px;
                height: 80%;
                position: absolute;
                top: 10%;
                left: 0;
                background: $main;
                border-radius: 2px;
            }
        }
        h5{
            font-size: 20px;
            font-weight: 700;
            background: #f2f2f2;
            padding: 4px 15px;
            margin: 0 0 16px;
            @media screen and (max-width:$width - 1px){
                font-size: 18px;   
            }
            @media screen and (max-width:$responsive){
                font-size: 16px;   
            }
        }
        h6{
            position: relative;
            font-size: 18px;
            font-weight: 700;
            padding: 0 0 0 18px;
            margin: 0 0 8px;
            @media screen and (max-width:$width - 1px){
                font-size: 16px;   
            }
            @media screen and (max-width:$responsive){
                font-size: 15px;   
            }
            &:before{
                content: '';
                width: 12px;
                height: 12px;
                border: $main 4px solid;
                border-radius: 50%;
                position: absolute;
                top: 50%;
                left: 0;
                transform: translateY(-50%);
            }
        }
        table{
            border: $border 1px solid;
            border-collapse: collapse;
            width: 100%;
            margin: 0 0 40px;
            @media screen and (max-width:$width - 1px){
                margin: 0 0 32px;   
            }
            @media screen and (max-width:$responsive){
                margin: 0 0 24px;   
            }
            tbody,
            thead{
                tr{
                    td,th{
                        padding: 15px;
                        text-align: left;
                        vertical-align: middle;
                        border: $border 1px solid;
                    }
                    th{
                        background: #f2f2f2;
                    }
                }
            }
        }
        img{
            max-width: 100%;
        }
        iframe{
            width: 100%;
            height: 300px;
            vertical-align: bottom;
            margin: 0 0 40px;
            @media screen and (max-width:$width - 1px){
                margin: 0 0 32px;
                height: 250px;
            }
            @media screen and (max-width:$responsive){
                margin: 0 0 24px;
                height: 210px;
            }
        }
        p{
            margin: 0 0 40px;
            @media screen and (max-width:$width - 1px){
                margin: 0 0 32px;   
            }
            @media screen and (max-width:$responsive){
                margin: 0 0 24px;   
            }
        }
    }
}