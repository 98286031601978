.site-sitemap{
    ul{
        li{
            list-style: none;
            margin: 0 0 40px;
            padding: 0 0 40px;
            border-bottom: $border 1px solid;
			@media screen and (max-width:$responsive){
				margin: 0 0 32px;
				padding: 0 0 32px;
			}
            &:last-child{
                margin: 0;
                padding: 0;
                border: none;
            }
            a{
                color: $normal;
            }
            dl{
                @extend .clearfix;
                dt{
                    font-size: 20px;
                    font-weight: 700;
                    margin: 0 0 16px;
					@media screen and (max-width:$responsive){
						font-size: 16px;
						margin: 0 0 12px;
					}
                }
                dd{
                    float: left;
					@media screen and (min-width:$responsive + 1px){
						width: 23.5%;
						margin: 0 2% 2% 0;
						&:nth-of-type(4n+1){
							clear: both;
						}
						&:nth-of-type(4n+4){
							margin-right: 0;
						}
						&:nth-last-of-type(-n+4){
							margin-bottom: 0;
						}
					}
					@media screen and (max-width:$responsive){
						width: 48.5%;
						margin: 0 3% 3% 0;
						&:nth-of-type(2n+1){
							clear: both;
						}
						&:nth-of-type(2n+2){
							margin-right: 0;
						}
						&:nth-last-of-type(-n+2){
							margin-bottom: 0;
						}
					}
                    a{
                        background: $main;
                        border: $main 2px solid;
                        border-radius: 6px;
                        display: block;
                        padding: 12px 0;
                        text-align: center;
                        font-weight: 700;
                        color: #FFF;
                        &:hover{
                            background: #FFF;
                            border-color: $sub;
                            color: $sub;
                        }
                    }
                }
            }
        }
    }
}